<template>
  <div>
    <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>开票设置</div>
      <el-card class="main"> 
          <div class="top">
              <span class="left">开票信息</span>
               <el-button style="float: right; padding: 3px 0;margin-right:100px" type="text" @click="upflage=false">设置</el-button>
          </div>
          <div id="diaa">
              <el-form ref="dataForm" :model="dataForm" label-width="220px" class="form itemcalss">
                <el-form-item label="企业名称">
                    <span>{{dataForm.companyName}}</span>
                </el-form-item>
                <el-form-item label="统一社会信用代码">
                   <span>{{dataForm.companyTaxNum}}</span>
                 </el-form-item>
                <el-form-item label="对公账户开户行">
                   <span v-if="upflage">{{dataForm.bankName}}</span>
                    <el-input  v-else v-model="dataForm.bankName" style="width:500px"></el-input>
                </el-form-item>
                <el-form-item label="对公账户号">
                   <span v-if="upflage">{{dataForm.bankNo}}</span>
                    <el-input  v-else v-model="dataForm.bankNo" style="width:500px"></el-input>
                </el-form-item>
                <el-form-item label="公司地址">
                   <span v-if="upflage">{{dataForm.companyAddress}}</span>
                    <el-input  v-else v-model="dataForm.companyAddress" style="width:1000px"></el-input>
                </el-form-item>
            </el-form>
          </div>
           <div class="top" style="margin-top:40px">
              <span  class="left">地址信息</span>
               <el-button style="float: right; padding: 3px 0;margin-right:100px" type="text" @click="addAddressFlage=true">+新增收件地址</el-button>
          </div>
          <div class="boxtop">
            <el-carousel type="card" arrow="always" :loop="false" :initial-index="1"  ref="carousel"
                    indicator-position="none" :autoplay="false">
              <el-carousel-item   v-for="(item,index) in addressArr"  :key="item.index"  @click.native="inter()">
                  <el-card class="box-card" :class="{active:index== activeIndex }">
                      <div slot="header" class="clearfix">
                        <span style="font-size:16px;float: left">收件信息</span>
                        <!-- <el-button style="float: right; padding: 8px" type="text" class="mini">保存</el-button> -->
                        <el-button style="float: right; padding: 8px" type="text" class="mini" @click="updata(index)" v-if="item.edit==1">编辑</el-button>
                        <el-button style="float: right; padding: 8px" type="text" class="mini" @click="saveaddress(index)" v-else>保存</el-button>
                      </div>
                      <div>
                      <el-form ref="addressArr" :model="addressArrobj" label-width="100px" class="form itemcalss" >
                          <el-form-item label="收件人姓名">
                            <span class="leftfont" v-if="item.edit==1">{{item.userName}}</span>
                              <el-input v-else v-model="item.userName"  :rows="5" ></el-input>
                          </el-form-item>
                          <el-form-item label="联系电话">
                             <span v-if="item.edit==1">{{item.userMobile}}</span>
                              <el-input v-model="item.userMobile" v-else></el-input>
                          </el-form-item>
                            <el-form-item label="邮编">
                               <span v-if="item.edit==1">{{item.postCode}}</span>
                              <el-input v-model="item.postCode" v-else></el-input>
                          </el-form-item>
                            <el-form-item label="收件人地址">
                               <span v-if="item.edit==1" class="adresssty">{{item.userAddress}}</span>
                              <el-input v-model="item.userAddress" v-else></el-input>
                          </el-form-item>
                      </el-form>
                    </div>
                  </el-card>
               </el-carousel-item>
              </el-carousel>
          </div>
           <div class="top" style="margin-top:40px">
              <span  class="left">邮寄方式</span>
           </div>
           <div style="margin-left:160px;margin-top:40px">
              <el-radio v-model="radio" label="502010">月寄</el-radio>
              <el-radio v-model="radio" label="502020">邮寄到付</el-radio>
              <div v-if="radio==502010" style="margin-top:14px">月寄是截止到每月最后一天统一邮寄，快递默认顺丰，无需承担费用。</div>
              <div v-else style="margin-top:14px">邮寄到付是每结算成功一批次，寄一次发票.工作人员收到发票申请后，会在一个工作日内安排寄出，邮寄到付产生的快递费需要自行承担。</div>
           </div>
           <div class="btnbtn">
               <el-button type="primary" @click="save" >保存</el-button>
               <el-button type="primary" style="margin-left:60px">取消</el-button>
           </div>
          
      </el-card>
      <!-- 新增地址 -->
       <el-dialog
      title="新增收货地址"
      width="40%"
       :visible.sync="addAddressFlage"
      >
           <div style="transform:translateY(-30px)">
       </div>
        <el-form ref="AddDataForm" :rules="dataRule" :model="AddDataForm" label-width="140px" class="form">
            <el-form-item label="收件人姓名"  prop="userName">
                <el-input v-model="AddDataForm.userName" ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="userMobile">
                <el-input v-model="AddDataForm.userMobile" ></el-input>
            </el-form-item>
              <el-form-item label="邮编" prop="postCode">
                <el-input v-model="AddDataForm.postCode" ></el-input>
            </el-form-item>
              <el-form-item label="收件人地址" prop="userAddress">
                <el-input v-model="AddDataForm.userAddress" ></el-input>
            </el-form-item>
        </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center;">
        <el-button type="primary" @click="surechange('AddDataForm')" >保存</el-button>
        <el-button type="primary"  @click="resetForm('AddDataForm')" >取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {add,addressInfo,saveAddressInfo,saveInfo,delAddressInfo} from  "@/api/ticketing.js";
import { isMobile } from '@/utils/validate' 
export default {
  data() {
     var validateMobile = (rule, value, callback) => {
        if (!isMobile(value)) {
          callback(new Error('联系电话格式错误'))
        } else {
          callback()
        }
      }
    return {
      addAddressFlage:false,
      upflage:true,
      dataForm:{},//开票设置-开票信息
      addressArr:[],//地址
      addressArrobj:{},
      AddDataForm:{
        "userName": "",
        "userMobile": "",
        "postCode": "",
        "userAddress": "",
        "merchantId":""
      },//add地址
      dataRule: {
          userName: [
            { required: true, message: '收件人姓名不能为空', trigger: 'blur' },
             { min: 2, max: 30, message: '收件人姓名长度在 2 到 30 个字符', trigger: 'blur' }
          ],
         userMobile: [
            { required: true, message: '联系电话不能为空', trigger: 'blur' },
            { validator: validateMobile, trigger: 'blur' }
          ],
           userAddress: [
            { required: true, message: '收件人地址不能为空', trigger: 'blur' },
             { min:10, max: 120, message: '收件人地址长度在 10 到 120 个字符', trigger: 'blur' }
          ],
           postCode: [
            { required: true, message: '邮编不能为空', trigger: 'blur' },
             { min:6, max: 6, message: '邮编长度应为6个字符', trigger: 'blur' }
          ],
        },
         radio: '',
         activeIndex:"",//地址点击
      };
  },
  mounted(){
  this.info()
  },
  methods: {
  
  //开票信息
   info(){
     add().then(data=>{
       if (data&&data.code==200) {
          this.dataForm=data.data
          this.radio=data.data.sendType.toString()
       }
     })
      this.ress()
   },
   ress(){
      addressInfo().then(data=>{
       if (data&&data.code==200) {
          this.addressArr=data.data
          this.addressArr.map(item=>{
            item.edit=1
          })
          console.log( this.addressArr," this.addressArr");
       }
     })
   },
    surechange(dataForm){
    this.$refs[dataForm].validate((valid) => {
          if (valid) {
      this.AddDataForm.merchantId=this.dataForm.merchantId
      saveAddressInfo(this.AddDataForm).then(data=>{
       if (data&&data.code==200) {
         this.addAddressFlage=false
         this.ress()
         this.$message({
          message: '恭喜你，创建地址成功',
          type: 'success'
        }); 
        this.$refs[dataForm].resetFields();
        }
     })
        }
        })
    },
    inter(){
     this.activeIndex = this.$refs.carousel.activeIndex
    //  this.receiverInfoId=this.addressArr[this.activeIndex].id
     },
     updata(a){
       console.log(a,"ff");
       this.addressArr[a].edit=2
       this.$forceUpdate()
     },
  saveaddress(){
      let valuedata={
        addressId:this.addressArr[this.activeIndex].id
      }
      delAddressInfo(valuedata).then(data=>{
       if (data&&data.code==200) {
         this.upsave()
       }
    })
     
    
    },
    upsave(){
       let datavalue={
          merchantId:this.dataForm.merchantId,
          userName:this.addressArr[this.activeIndex].userName,
          userMobile:this.addressArr[this.activeIndex].userMobile,
          postCode:this.addressArr[this.activeIndex].postCode,
          userAddress:this.addressArr[this.activeIndex].userAddress
        }
      saveAddressInfo(datavalue).then(data=>{
       if (data&&data.code==200) {
          // this.addAddressFlage=false
          this.ress()
          this.$message({
              message: '修改成功',
              type: 'success'
            }); 
       }
    })
    },
    // 
    save(){
      let datavalue={
          merchantId:this.dataForm.merchantId,
          bankName:this.dataForm.bankName,
          bankNo:this.dataForm.bankNo,
          companyAddress:this.dataForm.companyAddress,
          sendType:this.radio
      }
       saveInfo(datavalue).then(data=>{
          if (data&&data.code==200) {
            this.upflage=true
            this.$message({
              message: data.msg,
              type: 'success'
            }); 
          }
       })
    },
    resetForm(formName){
      this.addAddressFlage=false
        this.$refs[formName].resetFields();
    }
  }
};
</script>

<style  scoped>
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
   
}
.main{
    margin-top: 10px;
}
.top{
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #e4e4e4;
  font-size:16px;
  margin-top: 20px;
}
#diaa{
  margin-top:30px
}
.top .left{
margin-left: 160px;
font-weight: 600;
}
 .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .box-card {
    width: 480px;
    display: inline-block;
    margin-left: 20px;
  }
  .boxtop {
  width: 100%;
  height: 340px;
  margin-top:30px
}
.mini{
   display: inline-block;
      height: 26px;
      line-height: 12px;
      text-align: center;
      width:60px;
      border: 1px solid #9e99b9;
      border-radius: 25px ;
      margin-left:14px;
     font-size: 10px;
}
 /* 表格------------- */
  /* /deep/ #dia .form {
    margin-top:10px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
}
 #dia  .el-form-item {
  margin-bottom: 0px;
  border-bottom: 1px solid #ccc;
}
div /deep/ #dia  .el-input__inner {
  border: #fff;
}
div /deep/ #dia .el-form-item__label {
    text-align: center;
    background: #f0f0f0;
   border-right: 1px solid #ccc;
}
div /deep/ #dia .el-input.is-disabled .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
}
div /deep/ #dia .el-input .el-input__inner {
    text-align: center;
    background: #FFF;
    color: black;
} */
.container {
  display: inline-block;
  width:1320px;
  height: 300px;
  background: forestgreen;
  
}
.btn{
  /* margin-top: 100px; */
  transform: translateY(100px);
  font-size: 40px;
  display: inline-block;
}


.el-carousel__item.el-carousel__item--card.is-in-stage {
  text-align: center;
 }
/*修改高度*/
 /deep/.el-carousel__container {
  height: 300px;
 }
 .van-collapse-item__content {
  padding: 14px 0;
 }
/*左右箭头的样式*/
 button.el-carousel__arrow.el-carousel__arrow--left, button.el-carousel__arrow.el-carousel__arrow--right {
  font-size: 12px;
  height: 20px;
  width: 20px;
  background: #A099F0;
 }
.itemcalss .el-form-item{
height: 20px;
}
/deep/.timr.el-form .el-form-item__error {
  position: fixed;
  bottom: 100px;
  right: 25% !important;
  left: unset;
}
.btnbtn{
  text-align: center;
  margin-top: 80px;
}
.active{
  color: #fff !important;
  background: #9e99b9;
}
/deep/.active .el-form-item__label{
  color: #fff !important;
}
.active .mini{
border: 0.005556rem solid #ffff;
color: #fff;
}
/deep/ .leftfont {
  display: inline-block;
  text-align: left;
}
 /deep/.el-form-item__content{
   text-align: left;
 }
 .adresssty{
   display: inline-block;
  width: 100%;   /*一定要设置宽度，或者元素内含的百分比*/
  overflow:hidden; /*溢出的部分隐藏*/
  white-space: nowrap; /*文本不换行*/
  text-overflow:ellipsis;
 }
</style>


